import React from 'react';

class Loader extends React.Component {
    render() {
        return (
            <div className="d-flex justify-content-center align-items-center">
                <div className="spinner-grow text-primary" role="status">
                </div>
            </div>
        );
    }
}

export default Loader;