import React, { Suspense } from "react";
import Loader from "./Loader";
import { LazyLoadImage } from "react-lazy-load-image-component";

export const EventCard = props => {
    return (
        <div className="d-flex flex-column aling-items-center">
            <Suspense fallback={<Loader />}>
                <LazyLoadImage
                    loading=" lazy"
                    src={props.image}
                    alt="banner evento"
                    width="auto"
                />
            </Suspense>
            {props.url && (
                <h5 className="pt-3 pb-1">
                    Garanta sua{" "}
                    <a
                        href={props.url}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        inscrição aqui
                    </a>
                    !
                </h5>
            )}
        </div>
    );
};
